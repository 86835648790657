import React, { useState } from "react";
import Input from "./Input/Input";
import styles from "./LoginSignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import CheckBox from "./Checkbox/CheckBox";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
const Login = () => {
  const navigate = useNavigate();
  const [rememberMe, setMemberMe] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const LoginHandler = async (e) => {
    e.preventDefault();
    let url = process.env.REACT_APP_API_URL;
    let body = {
      email: values.email,
      password: values.password,
    };
    toast("Authenticating...", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
      type: "info",
    })
    setTimeout(() => {}, 1000);
    
    try {
      let { data, status } = await axios.post(url + "/auth/login", body, {
        headers: {
          "Content-Type": "application/json",
          "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      });
      Cookies.set("auth-token", data.token);
      navigate("/dashboard");
    } catch(e) {
      toast("Invalid Credentials", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        type: "error",
      })
    }


  };

  const inputs = [
    {
      label: "Email Address",
      type: "email",
      name: "email",
      placeholder: "Enter your email address",
    },
    {
      label: "Password ",
      type: "password",
      name: "password",
      placeholder: "Enter your passcode",
    },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <h2 className={styles.title}>Login</h2>
          <p className={styles.text}>
            {/* Sign in into your account using your email and passcode. */}
          </p>
        </div>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={styles.form}
        >
          {inputs.map((input, i) => (
            <Input
              {...input}
              key={i}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
          <div className={styles.rememberOrForgot}>
            <CheckBox
              label="Remember Me"
              checked={rememberMe}
              setChecked={setMemberMe}
            />{" "}
            <button className={styles.forgot}>Forgot Code?</button>
          </div>{" "}
          <button className={styles.button} onClick={LoginHandler}>
            Login
          </button>
        </form>
        <div className={styles.suggetionContainer}>
          <p className={styles.suggestionText}>New on our platform? </p>{" "}
          <Link to="/signup" className={styles.toggle}>
            &nbsp;Create an account
          </Link>
        </div>
      </div>

      <div className={styles.overlay}></div>
    </>
  );
};

export default Login;
